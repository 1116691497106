import React, { createContext, useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

interface LanguageContextProps {
  language: string;
  setLanguage: (lang: string) => void;
}

const getDefaultLanguage = () => {
  const userAgent = navigator.userAgent.toLowerCase();

  if (userAgent.includes("naver")) return "ko"; // NaverBot → 한국어
  if (userAgent.includes("yahoo") || userAgent.includes("slurp")) return "jp"; // Yahoo Japan → 일본어
  // console.log("navigator default language: ", navigator.language.split("-")[0]);
  // 일반 사용자는 navigator.language 사용
  if (navigator.language.split("-")[0] === "ja") {
    return "jp";
  }
  if (navigator.language.split("-")[0] === "zh") {
    return "zh";
  }
  return navigator.language.split("-")[0] || "ko";
};

const LanguageContext = createContext<LanguageContextProps>({
  language: getDefaultLanguage(),
  setLanguage: () => {},
});

export const LanguageProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const location = useLocation(); // React Router의 useLocation 훅
  const [language, setLanguage] = useState(() => {
    const initialPathParts = location.pathname.split("/");
    return initialPathParts[1] || getDefaultLanguage(); // URL에서 언어 추출 또는 기본값 설정
  }); // 기본 언어 설정

  useEffect(() => {
    const pathParts = location.pathname.split("/"); // URL을 '/' 기준으로 분리
    const langFromURL = pathParts[1]; // 첫 번째 파라미터
    if (langFromURL && langFromURL !== language) {
      setLanguage(langFromURL); // 언어 상태 업데이트
    }
    // console.log("LanguageContext: ", langFromURL);
  }, [location.pathname]); // URL이 바뀔 때마다 실행

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
