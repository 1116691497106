import React from "react";
import { Fab, Tooltip } from "@mui/material";
// import ChatIcon from "@mui/icons-material/Chat";
import lineIcon from "./images/lineIcon.png";
import wa_logo from "./images/wa_logo.png";
import { useLanguage } from "../LanguageContext";

const LineFloatingButton: React.FC = () => {
  const { language } = useLanguage(); // 현재 언어 상태 가져오기

  const handleClick = () => {
    window.location.pathname.startsWith("/jp")
      ? window.open("https://lin.ee/rLULi2R", "_blank")
      : window.location.pathname.startsWith("/th")
      ? window.open("https://lin.ee/JYDSW8S", "_blank")
      : window.location.pathname.startsWith("/zh")
      ? window.open("https://lin.ee/3hzX4Um", "_blank")
      : window.location.pathname.startsWith("/en")
      ? window.open("https://wa.me/message/UBPMRBXE74OFD1", "_blank")
      : window.open("http://pf.kakao.com/_xhxmizn/", "_blank");
  };

  return (
    <Tooltip title="LINE" placement="left">
      <Fab
        color="primary"
        aria-label="line-chat"
        onClick={handleClick}
        sx={{
          width: "50px",
          height: "50px",
          position: "fixed",
          bottom: 10,
          right: 10,
          zIndex: 1000,
        }}
      >
        <img
          src={!window.location.pathname.startsWith("/en") ? lineIcon : wa_logo}
          alt="message"
          style={{ width: "100%", height: "100%" }}
        />
      </Fab>
    </Tooltip>
  );
};

export default LineFloatingButton;
