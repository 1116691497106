// Firestore에 데이터를 저장하는 Node.js 스크립트
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  setDoc,
  doc,
  collection,
  updateDoc,
  getDoc,
  arrayUnion,
} from "firebase/firestore";
import React, { useEffect } from "react";
import category from "../components/text_assets/category.json";
import brief from "../components/text_assets/brief.json";
import event from "../components/text_assets/event_th.json";
import eventCategory from "../components/text_assets/eventCategory.json";
import title from "../components/text_assets/title.json";
import startingPrice from "../components/text_assets/startingPrice.json";
import detail from "../components/text_assets/detail.json";
import bodyCategory from "../components/text_assets/bodyCategory.json";
import theme_jp from "../components/text_assets/theme_jp.json";
import category_jp from "../components/text_assets/category_jp.json";
import theme_th from "../components/text_assets/theme_th.json";

const XLSX = require("xlsx");

function Upload() {
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_CONFIG_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_CONFIG_AUTODOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_CONFIG_PROJECTID,
    storageBucket: process.env.REACT_APP_FIREBASE_CONFIG_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_CONFIG_MESSAINGSENDERID,
    appId: process.env.REACT_APP_FIREBASE_CONFIG_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_CONFIG_MEASUREMENTID,
  };
  // Firebase 초기화
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  async function uploadTheme() {
    const collectionRef = collection(db, "theme_th"); // 컬렉션 참조
    try {
      for (const obj of theme_th) {
        const docRef = doc(collectionRef, String(obj.key)); // key 값을 ID로 설정
        await setDoc(docRef, obj); // Firestore에 데이터 저장
        console.log(`Document with ID ${obj.key} added successfully.`);
      }
      console.log("All documents uploaded successfully.");
    } catch (error) {
      console.error("Error uploading documents: ", error);
    }
  }

  async function updateContentsFromJson() {
    try {
      // jsonData의 각 id에 대해 Firestore 업데이트
      const updatePromises = Object.keys(category_jp).map(async (id) => {
        // const intId = parseInt(parseInt(id) / 100);
        const docRef = doc(db, "category_jp", id); // 문서 참조 가져오기
        // const contents = category[id]; // 해당 id의 행렬 데이터
        // const briefV = brief[id];
        // const events = event[id]; // 해당 id의 이벤트 데이터
        // const cat14 = [id];

        // Firestore에 contents 필드 업데이트
        await setDoc(docRef, {
          description: category_jp[id].description,
          recommendation: category_jp[id].recommendation,
          precaution: category_jp[id].precaution,
          feature: category_jp[id].feature,
          FAQ: category_jp[id].FAQ,
          // contents: bodyCategory,
        });
      });
      console.log("406 cateogry updated successfully!");
      // });

      // 모든 업데이트가 완료될 때까지 기다림
      // await Promise.all(updatePromises);

      console.log("All documents updated successfully!");
    } catch (error) {
      console.error("Error updating contents field:", error);
    }
  }
  async function uploadEvent() {
    try {
      for (const [key, value] of Object.entries(event)) {
        // 각 key를 문서 ID로 사용하고 value를 문서 내용으로 설정
        const docRef = doc(db, "event_th", key);
        await setDoc(docRef, value);
        console.log(`Document with ID ${key} has been added to collection`);
      }
      console.log("All documents uploaded successfully!");
    } catch (error) {
      console.error("Error uploading documents: ", error);
    }
  }

  async function updateCategoriesFromBriefs(file) {
    for (const [id, value] of Object.entries(file)) {
      const index = parseInt(id); // key를 숫자로 변환

      // 1. Firestore 문서 ID 계산
      const docId = Math.floor(index / 100).toString(); // 100으로 나눈 몫

      try {
        // 2. Firestore에서 문서 가져오기
        const docRef = doc(db, "theme", docId); // 컬렉션 이름 변경
        // await docRef.update({ title: value });
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          // 3. categories 배열 가져오기
          const data = docSnap.data();
          const categories = data.categories || [];

          // 4. categories 배열 업데이트
          const updatedCategories = categories.map((item) => {
            if (String(item.category) === id) {
              return { ...item, startingPrice: value }; // title 추가
            }
            return item; // 일치하지 않는 경우 그대로
          });

          // 5. Firestore 업데이트
          await updateDoc(docRef, { categories: updatedCategories });
          console.log(
            `Document ${docId} updated successfully for index ${index}.`
          );
        } else {
          console.log(`Document ${docId} does not exist.`);
        }
      } catch (error) {
        console.error(
          `Error updating document ${docId} for index ${index}:`,
          error
        );
      }
    }
  }

  const insertNewItemAtIndex = async () => {
    const docRef = doc(db, "category_zh", "603"); // 컬렉션과 문서 ID 지정
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      console.error("문서를 찾을 수 없습니다.");
      return;
    }

    // 현재 contents 배열 가져오기
    const contents = docSnap.data().contents || [];

    // 새로 추가할 map (객체)
    const newItem = {
      description: "",
      brief:
        "รักษารอยแผลเป็นด้วยเทคนิคซิกเนเจอร์ของ Pria โดยไม่ทิ้งรอยช้ำหรือรอยดำ!",
      category: 603,
      startingPrice: 129000,
      title: "แอร์ซับซิชชั่น",
    };
    const newItemsforeign = [
      {
        description: "",
        key: 3701,
        event: 1,
        price: 129000,
        title: "แอร์ซับซิชชั่น 1ครั้ง",
      },
      {
        description: "",
        key: 3702,
        event: 1,
        price: 349000,
        title: "แอร์ซับซิชชั่น 3ครั้ง",
      },
      {
        description: "",
        key: 3703,
        event: 1,
        price: 499000,
        title: "แอร์ซับซิชชั่น 5ครั้ง",
      },
      {
        description: "",
        key: 3704,
        event: 0,
        price: 250000,
        title: "แอร์ซับซิชชั่น 1ครั้ง",
      },
    ];
    const newItem2 = {
      description:
        "ตัวเลือก) ฉีดแอร์ไชน์อินเจคเตอร์ + 3 หมื่นวอนตัวเลือก) โพเทนซาพัมพิงทิป + 9 หมื่นวอน",
      event: 4,
      key: 30006,
      price: 99000,
      title: "จูวีลุคสกินบูสเตอร์ 1cc 1 ครั้ง",
    };

    const newItems = [
      {
        description:
          "选项）增加Air Shine注射器 + 3万韩元 @选项）增加Potenza泵注头 + 9万韩元",
        event: 2,
        key: 6304,
        price: 449000,
        title: "Juvelook 水光针 4cc 1次",
      },
      {
        description:
          "选项）增加Air Shine注射器 + 3万韩元 @选项）增加Potenza泵注头 + 9万韩元",
        event: 2,
        key: 6305,
        price: 1199000,
        title: "Juvelook 水光针 4cc 3次",
      },
    ];
    // 3번째 순서(인덱스 2) 이후에 추가
    // contents.splice(0, 0, newItem2); // 3번째 위치에 삽입
    contents.splice(3, 0, ...newItems); // 3번째 위치에 삽입

    // Firestore 업데이트
    await updateDoc(docRef, {
      contents: contents, // 수정된 배열 저장
    });

    console.log("새로운 데이터가 2번째 위치에 추가되었습니다.");
  };

  const sortCategoriesByPrice = async () => {
    const docRef = doc(db, "category_jp", "1201"); // Firestore 문서 참조
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      console.error("문서를 찾을 수 없습니다.");
      return;
    }

    let categories = docSnap.data().categories || [];

    if (categories.length < 2) {
      console.error("정렬할 데이터가 충분하지 않습니다.");
      return;
    }

    // 🔹 마지막 요소(Skin booster Package) 분리
    const lastItem = categories[categories.length - 1]; // 마지막 멤버
    const lastTwoItems = categories.slice(-5);
    categories = categories.slice(0, -5); // 마지막 멤버 제외한 배열

    // 🔹 startingPrice 값 기준으로 오름차순 정렬
    categories.sort((a, b) => a.startingPrice - b.startingPrice);

    // 🔹 정렬된 배열 + 마지막 멤버 다시 추가
    // categories.push(lastItem);
    categories = [...categories, ...lastTwoItems];

    // 🔹 Firestore 업데이트
    await updateDoc(docRef, { categories: categories });

    console.log("카테고리 정렬 완료");
  };

  // async function updateDocuments() {
  //   try {
  //     const promises = Object.keys(updates).map((id) => {
  //       // db.collection("category").doc(id).update(updates[id]);
  //       collection(db, "category").doc(id).update(updates[id]);
  //     });

  //     await Promise.all(promises);
  //     console.log("Documents successfully updated");
  //   } catch (error) {
  //     console.error("Error updating documents:", error);
  //   }
  // }
  useEffect(() => {
    // uploadEvent();
    // updateContentsFromJson();
    // uploadTheme();
    // updateContentsFromJson();
    // updateCategoriesFromBriefs(startingPrice);
    // uploadJsonData();
    // const data = excelToJson("uploadExcel.xlsx");
    // console.log(data);
    // insertNewItemAtIndex();
    // sortCategoriesByPrice();
  }, []);

  return (
    <div className="container">
      <h1 className="title">주의사항</h1>
      <ul style={{ padding: 0 }}>안녕하세요</ul>
    </div>
  );
}
export default Upload;
