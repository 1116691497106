import { add } from "date-fns";
import { on } from "events";

export const locales = {
  ko: {
    potenzaTitle: "포텐자",
    potenzaSubtitle: "프리아의 빈틈없이 포텐자",
    ondaTitle: "온다",
    ondaSubtitle: "통증없이 효과가 바로온다!",
    onedayporezeroTitle: "원데이 포어제로테라피",
    onedayporezeroSubtitle: "화제의 바로 그 시술!",
    juvelookTitle: "쥬베룩 스킨부스터",
    juvelookSubtitle: "모공, 잔주름, 자연스러운 볼륨엔",
    nasolabialTitle: "팔자 필러",
    nasolabialSubtitle: "차원이 다른 디테일",
    ultheraTitle: "울쎄라",
    ultheraSubtitle: "1:1 디자인 울쎄라!",
    howwasyourday: "오늘 시술은 어떠셨나요?",
    gotosurvey: "평가하러 가기 ❯",
    surveyPolicy:
      "*설문에 참여해주신 분 중 5분께 매월 말, 추첨을 통해 스타벅스 쿠폰을 드립니다.",
    wrongBirthday: "올바른 생년월일을 입력해주세요.",
    dateofbirth: "생년월일",
    noReservationHistory: "예약 내역이 없습니다.",
    reservationCheckComment: "",
    bookingCancelComment:
      "예약 취소 요청이 완료되었습니다! 반영되기까지 시간이 소요될 수 있습니다.",
    monthlyEventRule:
      "*이 달의 이벤트는 해당되는 월 동안 항목당 1회씩만 적용 가능합니다.",
    reservationCancelConfirm: "예약을 취소하시겠습니까?",
    yesCancelConfirm: "예",
    noCancelConfirm: "아니요",
    referToHomepage: "홈페이지 가격 참고",
    afterCounsel: "상담 후 결정",
    newYearPrice: "이달의 특가",
    secondEventName: "리쥬베룩 이벤트",
    oneSessionTrial: "1회체험가",
    openPrice: "오픈특가",
    originalPrice: "정가",
    booking: "예약하기",
    reservation: "시술 예약",
    reservationTitle: "시술 가격",
    event: "이벤트",
    consult: "상담하기",
    addToCart: "담기",
    newComer: "뭘 해야될지 모르겠을 때!",
    searchGuide: "궁금한 시술을 검색해보세요",
    searchPlaceholder: "검색어를 입력하세요",
    priaSignature: "프리아의 시그니처 시술",
    priaBest: "Best of Pria",
    priaEvent: "프리아의 이벤트",
    wayToPria: "오시는 길",
    address1: "서울시 서초구 강남대로 349",
    address2: "우남빌딩 13층 프리아의원",
    subway: "지하철",
    subway1: "(2호선) 강남역 7번 출구 도보 3분",
    subway2: "(신분당선) 강남역 5번 출구 도보 1분",
    bus: "버스",
    bus1: "정류장 우성아파트사거리, 수협서초지점,",
    bus2: "신분당선강남역, 신분당선・강남역4번출구",
    taxi: "택시",
    taxi1: "1층에 수협은행이 있습니다.",
    driving: "자가용",
    driving1: "건물 뒷편으로 오시면 발렛파킹 서비스 가능합니다.",
    driving2: "주차비는 시술 받으신 시간만큼 지원해드리며,",
    driving3: "발렛비는 3000원 별도 발생됩니다.",
    workingHour: "진료시간",
    workingHour1: "평일",
    workingHour2: "토요일",
    workingHour3: "공휴일 전부 운영 (설, 추석 당일 제외)",
    workingHour4: "일요일 휴진",
    policy: "이용약관",
    personalPolicy: "개인정보처리방침",
    keywordAlert: "검색어를 입력해주세요.",
    openSpecialPrice: "오픈특가",
    openEventRule1: "*오픈특가는 1월 한달간 적용됩니다.",
    openEventRule2:
      "*오픈파격특가는 카톡친구 추가시 항목당 1회씩만 적용 가능합니다.",
    newyearEventRule1:
      "*신년 이벤트는 1월1일 ~ 1월31일 동안 항목당 1회씩만 적용 가능합니다.",
    eventRule1:
      "*1회체험가와 이 달의 특가는 항목당 1회씩만 적용 가능합니다.(4/1~4/30)",
    eventRule2:
      "*이벤트가와 1회체험가는 카톡친구 추가 시 적용됩니다.(4/1~4/30)",
    intro1: "당신만을 위한",
    intro2: "가장 Private한 클리닉, PRIA",
    intro3: "프리아는 오직 당신만을 위한",
    intro4: "아늑한 공간이 되기를 꿈꾸며 탄생했습니다.",
    intro5: "믿을 수 있는 정직함 속에서",
    intro6: "아름다움이 시작됩니다.",
    intro7: "당신과의 만남을 소중히 여기며,",
    intro8: "정직한 아름다움을 선사하겠습니다.",
    intro9: "프리아는 투명한 가격 정찰제와 함께",
    intro10: "정품, 정량을 철저하게 지킵니다.",
    intro11: "당신에게 오롯이 집중합니다.",
    intro12: "숙련된 의료진의 판단에 따라 개별 고객의",
    intro13: "특성을 고려한 1:1 맞춤시술을 제공합니다.",
    intro14: "객관적인 진단 장비를 활용하여",
    intro15: "더욱 과학적인 관리를 제공합니다.",
    intro16: "불필요한 권유가 아닌, 시술에 대한",
    intro17: "정확한 정보전달을 위한 상담을 진행합니다.",
    doctors: "PRIA의 의료진",
    intro_clinic: "병원 소개",
    intro_personnel: "의료진 소개",
    //장바구니 화면
    reservationComplete: "예약이 완료되었습니다!",
    cartIsEmpty: "장바구니가 비어있습니다.",
    chooseDate: "날짜와 시간을 선택해주세요.",
    chooseProcedure: "시술 직접 선택",
    afterCouncel: "상담 후 결정",
    pleaseChooseProcedure1: "관심있는 시술 혹은 이벤트를",
    pleaseChooseProcedure2: "추가해주세요.",
    addProcedure: "시술/이벤트 추가하기",
    additionalRequirement: "추가 요청 사항",
    personalizedConsultation: "1대1 맞춤상담",
    personalizedConsultComment1: "1대1 맞춤상담 후에",
    personalizedConsultComment2: "시술을 결정하실 수 있습니다.",
    pleaseWriteRequirement:
      "상담받고 싶은 부위나 궁금하신 내용을 자유롭게 적어주세요.",
    pickDate: "날짜 선택",
    authen: "본인인증",
    yourName: "이름",
    yourPhoneNumberOnly: "전화번호 (숫자만)",
    verifiCode: "인증번호",
    required: "필수",
    iAgree: "개인(진료)정보 제3자 제공에 동의합니다.",
    inCaseofFellow:
      "함께 내원 예정인 분이 계시다면 꼭 추가요청사항에 함께 오실 분의 성함과 연락처를 적어주세요:)",
    seeMore: "보기",
    paymentMethod: "결제는 내원시 진행됩니다.",
    VAT: "*모든 가격은 10% 부가세 별도입니다.",
    //상세 화면
    recommendation: "시술 추천 대상",
    features: "시술 특징",
    precaution: "시술 후 주의사항",
    directReservation: "바로 예약하기",
    //사이드 메뉴
    quickBooking: "빠른예약",
    reservationCheck: "예약확인/변경",
    cart: "장바구니",
    priaIntro: "프리아 소개",
    Insta: "인스타",
    SNS: "카카오톡",
    //예약확인
    reservationDate: "예약일시",
    year_: "년",
    month_: "월",
    day_: "일",
    time_: "시",
    minute_: "분",
    yourHistory: "님의 예약내역",
    yourRequest: "요청하신 내용",
    yourInquiry: "카카오톡 문의하기",
    yourCancelRequest: "취소 요청하기",
    reservationGuide: "",
    pleaseInsertNameAndPhone: "이름과 전화번호를 입력해주세요.",
    //검색화면
    whatrulookingfor: "어떤 시술을 찾으시나요?",
    searchwords: "인기검색어",
    recentSearch: "최근 검색어",
    totalPrice: "총 예상 금액",
    //상담방법 팝업
    consultMethod: "",
    byVisit: "방문상담",
    byCall: "전화상담",
    bySNS: "카톡문의",
    closer: "닫기",
    closePopup1week: "1주동안 다시 보지 않기",
  },
  en: {
    potenzaTitle: "POTENZA",
    potenzaSubtitle: "Flawless Potenza by PRIA",
    ondaTitle: "Onda",
    ondaSubtitle: "Immediate effects, pain-free!",
    onedayporezeroTitle: "1-day Porezero therapy",
    onedayporezeroSubtitle: "The treatment everyone is talking about!",
    juvelookTitle: "Juvelook Skinbooster",
    juvelookSubtitle: "For pores and fine wrinkles",
    nasolabialTitle: "Nasolabial Filler",
    nasolabialSubtitle: "Super-detailed Filler",
    ultheraTitle: "Ultherapy",
    ultheraSubtitle: "1:1 Personalized Ulthera!",
    howwasyourday: "How was your treatment today?",
    gotosurvey: "Go to the survey ❯",
    surveyPolicy:
      "*At the end of each month, five participants will be randomly selected to receive a Starbucks coupon.",
    wrongBirthday: "Please enter a valid date of birth.",
    dateofbirth: "Date of Birth (e.g., 900101)",
    noReservationHistory: "No reservation history found.",
    reservationCheckComment:
      "Your appointment has been successfully confirmed! To provide you with additional information, please send your name to PRIA Clinic's official WhatsApp account (ID: +82 10-3853-3499).",
    bookingCancelComment:
      "Your cancellation request has been completed! It may take some time to be processed.",
    monthlyEventRule:
      "*The monthly event can only be applied once per item during that month.",
    reservationCancelConfirm: "Do you want to cancel the reservation?",
    yesCancelConfirm: "Yes",
    noCancelConfirm: "No",
    referToHomepage: "Please refer to website for price.",
    afterCounsel: "Determined after counseling",
    secondEventName: "Rejuvelook Event",
    newYearPrice: "Monthly Special",
    oneSessionTrial: "1 session trial",
    openPrice: "Open speical",
    originalPrice: "Regular price",
    booking: "Reservation",
    reservation: "Reservation",
    event: "Event",
    consult: "Consult",
    reservationTitle: "Price",
    addToCart: "Add",
    newComer: "I'm first in here!",
    searchGuide: "Find the procedure you're looking for.",
    searchPlaceholder: "Search",
    priaSignature: "PRIA's Signature",
    priaEvent: "PRIA's Events",
    wayToPria: "Way to PRIA",
    address1: "349 Gangnam-daero, Seocho-gu, Seoul",
    address2: "13F, PRIA Clinic",
    subway: "Subway",
    subway1: "(Line 2) Gangnam Station Exit 7, 3 minutes walk",
    subway2: "(Shinbundang Line) Gangnam Station Exit 5, 1 minute walk",
    bus: "Bus",
    bus1: "Get off at Uiseong Apartment Intersection, Suhyup Seocho Branch",
    bus2: "Shinbundang Line Gangnam Station, Shinbundang Line Gangnam Station Exit 4",
    taxi: "Taxi",
    taxi1: "There is a Suhyup Bank on the first floor.",
    driving: "By car",
    driving1: "Valet parking service is available at the rear of the building.",
    driving2: "Parking fees are covered for the duration of your procedure,",
    driving3: "but an additional valet fee of 3,000 KRW will be charged.",
    workingHour: "Clinic Hours",
    workingHour1: "MON-FRI",
    workingHour2: "SAT",
    workingHour3:
      "Open on all public holidays (except for Lunar New Year's Day and Chuseok Day)",
    workingHour4: "Sunday closed",
    policy: "Terms of Use",
    personalPolicy: "Privacy Policy",
    keywordAlert: "Please enter a search term.",
    openSpecialPrice: "Open Special Price",
    openEventRule1:
      "*The Open Special Price is only applicable during this January.",
    openEventRule2: "*The Open Special Price can be applied once per item.",
    newyearEventRule1:
      "*The monthly promotion is only valid for the duration of the respective month, and each item can only be applied once.",
    eventRule1: "*The One-Time Trial Price can be applied only once per item.",
    eventRule2:
      "The event price and the one-time trial price are only applicable if you add PRIA Clinic's official account on LINE or KakaoTalk as a friend.(4/1~4/30)",
    intro1: "Private clinic for you,",
    intro2: "PRIA",
    intro3: "PRIA has born with the dream",
    intro4: "of becoming a cozy and comforting space for you.",
    intro5: "Beauty begins ",
    intro6: "with trustworthy honesty,",
    intro7: "We cherish every moment with you and try our best",
    intro8: "to give you the genuine beauty.",
    intro9: "PRIA ensures transparent pricing,",
    intro10: "and strictly adheres to genuine products and precise quantities.",
    intro11: "We focus on you.",
    intro12: "Based on the judgment of experienced medical staff,",
    intro13:
      "we provide 1:1 customized procedures tailored to individual needs.",
    intro14: "With objective diagnostic equipment,",
    intro15: "we offer more scientific care.",
    intro16:
      "we provide consultations focused on delivering accurate information about treatments,",
    intro17: "without unnecessary recommendations.",
    doctors: "PRIA's medical staff",
    intro_clinic: "About Pria",
    intro_personnel: "Doctors",
    //장바구니 화면
    reservationComplete: "Reservation is completed!",
    cartIsEmpty: "Cart is empty. Please add procedures.",
    chooseDate: "Please choose date and time.",
    chooseProcedure: "Choose procedures",
    afterCouncel: "Counsel first",
    pleaseChooseProcedure1: "Please add procedures or events",
    pleaseChooseProcedure2: "you are interested in.",
    addProcedure: "Add procedures/events",
    additionalRequirement: "Additional requests",
    inCaseofFellow:
      "If you are planning to visit with someone, please make sure to include their name and contact information in the additional requests section. :)",
    personalizedConsultation: "Personalized consultation",
    personalizedConsultComment1: "The most appropriate procedure for you",
    personalizedConsultComment2:
      "will be determined through personalized consultation",
    pleaseWriteRequirement:
      "Please let us know your concerns or any questions you have.",
    pickDate: "Select date",
    authen: "Identity verification",
    yourName: "Name",
    yourPhoneNumberOnly: "Phone Number (Numbers only)",
    verifiCode: "Verification Code",
    required: "Required",
    iAgree: "I agree to provide personal information to third parties.",
    seeMore: "See more",
    paymentMethod: "Payment will be processed upon your visit.",
    VAT: "*All prices are exclusive of VAT.(+10%)",
    //상세 화면
    recommendation: "Recommendation",
    features: "Features",
    precaution: "Precautions",
    directReservation: "Make an Appointment",
    //사이드 메뉴
    quickBooking: "Quick reservation",
    reservationCheck: "Reservation confirmation",
    cart: "Cart",
    priaIntro: "About Pria",
    Insta: "Instagram",
    SNS: "Line",
    //예약확인
    reservationDate: "Date",
    year_: ".",
    month_: ".",
    day_: ".",
    time_: ":",
    minute_: "",
    yourHistory: "'s reservation history",
    yourRequest: "Your request",
    yourInquiry: "Contact us on WhatsApp",
    yourCancelRequest: "Cancel reservation",
    reservationGuide:
      "A confirmation message (or SMS) will be sent to the contact number you provided! Even if you don't receive the message, your reservation will still be processed normally. However, for accurate confirmation, please search for @priaclinic.th on LINE and send the word RESERVATION CHECK or การยืนยันการนัดหมาย !:)",
    pleaseInsertNameAndPhone: "Please insert your name and phone number.",
    //검색화면
    whatrulookingfor: "Search procedures you are interested in.",
    searchwords: "Trending keywords",
    recentSearch: "Recent search",
    totalPrice: "Total Price",
    //상담방법 팝업
    consultMethod: "Counseling",
    byVisit: "Visit",
    byCall: "Call",
    bySNS: "WhatsApp",
    closer: "Close",
    closePopup1week: "Don't show again for 1 week",
  },
  jp: {
    potenzaTitle: "ポテンツァ",
    potenzaSubtitle: "PRIAの完璧なポテンツァ",
    ondaTitle: "オンダ",
    ondaSubtitle: "痛みなしで即効！",
    onedayporezeroTitle: "1デー ポアゼロ セラピー",
    onedayporezeroSubtitle: "今話題の人気施術！",
    juvelookTitle: "ジュベルックスキンブースター",
    juvelookSubtitle: "毛穴、小ジワ、自然なボリュームに！",
    nasolabialTitle: "ほうれい線フィラー",
    nasolabialSubtitle: "次元が違う「ディテール」フィラー",
    ultheraTitle: "ウルセラ",
    ultheraSubtitle: "1:1デザインのウルセラ！",
    howwasyourday: "本日の施術はいかがでしたか？",
    gotosurvey: "アンケートに回答する ❯",
    surveyPolicy:
      "*アンケートにご協力いただいた方の中から、毎月末に抽選で5名様にスターバックスのクーポンをプレゼントいたします。",
    wrongBirthday: "正しい生年月日を入力してください。",
    dateofbirth: "生年月日（例: 900101）",
    noReservationHistory: "予約履歴がありません。",
    reservationCheckComment:
      "ご予約を承りました。今後のご案内のために、PRIA ClinicのLINE公式アカウント（ID: @priaclinic）へお名前をメッセージでお送りください。",
    bookingCancelComment:
      "予約のキャンセルリクエストが完了しました！反映されるまでに時間がかかる場合があります。",
    monthlyEventRule:
      "*今月のイベントは、該当月の期間中、各項目につき1回のみ適用可能です。",
    reservationCancelConfirm: "本当に予約をキャンセルされますか？",
    yesCancelConfirm: "はい",
    noCancelConfirm: "いいえ",
    referToHomepage: "ホームページ参照",
    afterCounsel: "カウンセリング後に決定",
    secondEventName: "リジュベルックイベント",
    newYearPrice: "今月特価",
    oneSessionTrial: "1回体験価",
    openPrice: "オープン記念",
    originalPrice: "定価",
    booking: "ご予約",
    reservation: "ご予約",
    event: "キャンペーン",
    consult: "相談",
    addToCart: "追加",
    reservationTitle: "施術料金",
    newComer: "どの施術がいいのか分からない時！",
    searchGuide: "気になる施術を検索",
    searchPlaceholder: "検索ワードを入力してください",
    priaSignature: "プリアのシグネチャー施術",
    priaEvent: "プリアのイベント",
    wayToPria: "交通アクセス",
    address1: "ソウル市瑞草区カンナムデロ349",
    address2: "ウナムビル13階 プリアクリニック",
    subway: "地下鉄",
    subway1: "(2号線) 江南駅7番出口 徒歩3分",
    subway2: "(新盆唐線) 江南駅5番出口 徒歩1分",
    bus: "バス",
    bus1: "宇成アパート交差点・水協瑞草支店で下車　(ウソンアパートサゴリ、スヒョプソチョジジョム)",
    bus2: "新盆唐線 江南駅、新盆唐線・江南駅4番出口",
    taxi: "タクシー",
    taxi1: "ビルの一階にSH水協(SHスヒョプ)銀行があります。",
    driving: "車",
    driving1: "ビルの裏側にてバレットパーキングサービスがあります。",
    driving2: "施術時間分の駐車場料金はご提供致します。",
    driving3: "バレットパーキング料金3000ウォンは別途のお支払いが必要です",
    workingHour: "診療時間",
    workingHour1: "平日",
    workingHour2: "土曜",
    workingHour3: "祝日 通常診療 (旧正月・秋夕以外)",
    workingHour4: "日曜 休診",
    policy: "利用約款",
    personalPolicy: "個人情報処理方針",
    keywordAlert: "検索ワードを入力してください。",
    openSpecialPrice: "オープン記念価格",
    openEventRule1: "* オープン記念特価は1月いっぱい適用されます。",
    openEventRule2:
      "* オープン記念特価はLINEの公式アカウントを追加された場合、項目ごとに1回のみ使用できます。",
    newyearEventRule1:
      "*新春記念価格は1月1日から1月31日まで開催され、各項目につき1回のみ適用可能です。",
    eventRule1: "* 1回体験価格は項目ごとに1回ずつ使用できます。",
    eventRule2:
      "* イベント価格と1回体験価はLINEの公式アカウントを追加された場合適用できます。(4/1~4/30)",
    intro1: "あなただけのための",
    intro2: "最もPrivateなクリニック、PRIA",
    intro3: "プリアはあなただけのための",
    intro4: "温かく居心地の良い空間になるべく誕生しました。",
    intro5: "信頼できる正しさの中に",
    intro6: "美しさが始まります。",
    intro7: "あなたとの出会いを大切にし、",
    intro8: "正しい美しさをお贈りいたします。",
    intro9: "プリアは価格表示の透明性と",
    intro10: "正規品、定まれた容量を徹底的にお約束いたします。",
    intro11: "あなたを一途に思い続けます。",
    intro12: "熟練された医療スタッフの判断に基づきお客様の",
    intro13: "特徴を考慮した1:1のカスタム施術をご提供します。",
    intro14: "客観的な肌診断設備を活用し",
    intro15: "より科学的なケアをご提供いたします。",
    intro16: "不必要な押し売りはなく、施術に対する",
    intro17: "正確な情報をご提供するためにカウンセリングを行います。",
    doctors: "Priaの医療チーム",
    intro_clinic: "病院の紹介",
    intro_personnel: "医療チームの紹介",
    //장바구니 화면
    cartIsEmpty: "カートが空です。",
    reservationComplete: "予約が完了しました！",
    chooseDate: "日付と時間をお選びくださいませ。",
    chooseProcedure: "施術選択",
    afterCouncel: "カウンセリング後に決定",
    pleaseChooseProcedure1: "ご興味がある施術またはイベントを",
    pleaseChooseProcedure2: "追加してください。",
    addProcedure: "施術/イベント追加",
    additionalRequirement: "ご要望事項",
    personalizedConsultation: "マンツーマン相談",
    personalizedConsultComment1: "マンツーマン相談の後に",
    personalizedConsultComment2: "施術を決めていただけます。",
    pleaseWriteRequirement:
      "カウンセリングしたい部位や気になる内容をご自由に記入してください。",
    inCaseofFellow:
      "一緒に来院される予定の方がいらっしゃる場合は、ご要望事項欄にその方のお名前と連絡先をご記入ください :)",
    pickDate: "日程選択",
    authen: "本人確認",
    yourName: "お名前(ローマ字)",
    yourPhoneNumberOnly: "電話番号（数字のみ入力）",
    verifiCode: "認証番号",
    required: "必須",
    iAgree: "個人(診療)情報 第3者提供に同意します。",
    seeMore: "全文表示",
    paymentMethod: "お支払いはご来院時にお願いいたします。",
    VAT: "*すべての価格は消費税別途です。(+10%)",
    //상세 화면
    recommendation: "このような方にオススメ",
    features: "施術の特徴",
    precaution: "施術後の注意事項",
    directReservation: "今すぐ予約する",
    //사이드 메뉴
    quickBooking: "クイック予約",
    reservationCheck: "ご予約確認",
    cart: "カート",
    priaIntro: "Pria 紹介",
    Insta: "Instagram",
    SNS: "LINE",
    //예약확인
    reservationDate: "予約日時",
    year_: ".",
    month_: ".",
    day_: ".",
    time_: ":",
    minute_: "",
    yourHistory: "様のご予約内容",
    yourRequest: "ご要望事項",
    yourInquiry: "LINEでお問い合わせ",
    yourCancelRequest: "キャンセル申請",
    reservationGuide:
      "ご記入いただいた連絡先に予約確定のSMSをお送りいたします。万が一SMSが届かない場合でも、予約は確実に完了しております。より正確な確認のため、公式LINEアカウント@priaclinicを追加していただき、「予約確認」とメッセージをお送りください。:)",
    pleaseInsertNameAndPhone: "お名前と電話番号を入力してください。",
    //검색화면
    whatrulookingfor: "どの施術をおさがしですか？",
    searchwords: "人気検索ワード",
    recentSearch: "最近の検索ワード",
    totalPrice: "合計金額",
    //상담방법 팝업
    consultMethod: "相談する",
    byVisit: "ほうもん",
    byCall: "でんわ",
    bySNS: "LINE",
    closer: "閉じる",
    closePopup1week: "1週間表示しない",
  },
  th: {
    potenzaTitle: "โพเทนซา",
    potenzaSubtitle: "โพเทนซา ที่ Pria แม่นยำทุกจุด",
    ondaTitle: "ออนด้า",
    ondaSubtitle: "เห็นผลไว ไร้ความเจ็บ!",
    onedayporezeroTitle: "พอร์ซีโร่ เธอราพีสำหรับวันเดย์",
    onedayporezeroSubtitle: "ทรีตเมนต์ที่กำลังเป็นกระแส!",
    juvelookTitle: "จูวีลุคสกินบูสเตอร์",
    juvelookSubtitle: "รูขุมขน ริ้วรอย ปรับรูปหน้า",
    nasolabialTitle: "ฟิลเลอร์ร่องแก้ม",
    nasolabialSubtitle: "ฟิลเลอร์ 'ดีเทล' ระดับพรีเมียม",
    ultheraTitle: "อัลเทอร่า",
    ultheraSubtitle: "อัลเทอร่า ออกแบบเฉพาะคุณ!",
    howwasyourday: "วันนี้การรับบริการของคุณเป็นอย่างไรบ้าง?",
    gotosurvey: "ไปทำแบบประเมิน ❯",
    surveyPolicy:
      "*ทุกสิ้นเดือน เราจะสุ่มแจกคูปอง Starbucks ให้กับผู้เข้าร่วมแบบสอบถาม 5 ท่าน",
    wrongBirthday: "กรุณากรอกวันเดือนปีเกิดให้ถูกต้อง",
    dateofbirth: "วันเดือนปีเกิด (เช่น 900101)",
    noReservationHistory: "ไม่มีประวัติการจอง",
    reservationCheckComment:
      "การจองของท่านได้รับการยืนยันเรียบร้อยแล้วค่ะ! เพื่อให้เราสามารถให้ข้อมูลเพิ่มเติมแก่ท่านได้ กรุณาส่งชื่อของท่านไปที่ LINE Official Account ของ PRIA Clinic (ID: @priaclinic.th)",
    bookingCancelComment:
      "คำขอยกเลิกการจองของคุณเสร็จสมบูรณ์แล้ว! อาจใช้เวลาสักครู่ในการดำเนินการ",
    monthlyEventRule:
      "*โปรโมชั่นประจำเดือนสามารถใช้ได้เพียง 1 ครั้งต่อรายการตลอดทั้งเดือนค่ะ",
    reservationCancelConfirm: "คุณต้องการยกเลิกการจองหรือไม่?",
    yesCancelConfirm: "ใช่",
    noCancelConfirm: "ไม่",
    referToHomepage: "ตรวจสอบราคาจากเว็บไซต์",
    afterCounsel: "ตัดสินใจหลังปรึกษา",
    newYearPrice: "ราคาพิเศษประจำเดือน",
    secondEventName: "รีจูเวลุค โปรโมชั่น",
    oneSessionTrial: "ทดลอง 1 ครั้ง",
    openPrice: "ราคาพิเศษ",
    originalPrice: "ราคาปกติ",
    booking: "จอง",
    reservation: "จองทำหัตถการ",
    reservationTitle: "ราคาหัตถการ",
    event: "อีเวนต์",
    consult: "ปรึกษา",
    addToCart: "เพิ่มลงตะกร้า",
    newComer: "หากไม่แน่ใจว่าจะต้องเลือกรายการใด!",
    searchGuide: "ลองกดค้นหารายการหัตถการที่คุณต้องการทราบ",
    searchPlaceholder: "พิมพ์คำค้นหา",
    priaSignature: "ซิกเนเจอร์การรักษาโดยเฉพาะของพรีอา",
    priaEvent: "อีเว้นต์จากพรีอา",
    wayToPria: "วิธีการเดินทาง",
    address1: "Seoul, Seocho-gu, Gangnam-daero, 349",
    address2: "Woonam Building, 13th floor, Pria Clinic",
    subway: "รถไฟใต้ดิน",
    subway1: "(Line 2) เดินเพียง 3 นาทีจาก สถานีกังนัม ทางออกหมายเลข 7",
    subway2:
      "(Sinbundang Line) เดินเพียง 1 นาทีจาก สถาณีกังนัม ทางออกหมายเลข 8",
    bus: "รถบัส",
    bus1: "ป้ายรถประจำทางสี่แยกอูซองอพาร์ทเมนต์, สาขาซูฮยอปซอโช,",
    bus2: "สถานีสินนบุนดังสอนกังนัม, สินนบุนดังสอน・สถานีกังนัม ทางออกหมายเลข 4",
    taxi: "รถแท็กซี่",
    taxi1: "ลงหน้าธนาคาร Suhyeop ชั้น 1",
    driving: "รถส่วนตัว",
    driving1: "ที่ด้านหลังอาคาร มีบริการรับจอดรถ Valet Parking ",
    driving2: "ทางคลีนิคดูแลค่าจอดรถให้ตามเวลาที่เข้ารับการรักษา,",
    driving3: "สำหรับค่าบริการจอดรถแทน จะมีค่าใช้จ่ายเพิ่มเติม 3,000 วอน",
    workingHour: "เวลาทำการ",
    workingHour1: "วันธรรมดา",
    workingHour2: "วันเสาร์",
    workingHour3: "เปิดทำการทุกวันหยุดราชการ (ยกเว้นวันตรุษเกาหลีและวันชูซอก)",
    workingHour4: "หยุดทุกวันอาทิตย์",
    policy: "ข้อกำหนดการใช้บริการ",
    personalPolicy: "นโยบายความเป็นส่วนตัว",
    keywordAlert: "พิมพ์คำค้นหา",
    openSpecialPrice: "ราคาพิเศษช่วงเปิดใหม่",
    openEventRule1:
      "*ราคาพิเศษสำหรับช่วงเปิดคลินิกใหม่ จะใช้ได้เฉพาะในเดือนมกราคมเท่านั้นค่ะ",
    openEventRule2:
      "*ราคาพิเศษสำหรับเปิดคลีนิคใหม่ จะสามารถใช้ได้เพียง 1 ครั้งต่อรายการเมื่อเพิ่มเพื่อนในแอพ kakaotalk",
    newyearEventRule1:
      "*ข้อเสนอพิเศษสำหรับปีใหม่ จะใช้ได้แค่ 1 ครั้งต่อหนึ่งรายการเท่านั้น ระหว่างวันที่ 1 มกราคมถึง 31 มกราคม",
    eventRule1: "*ข้อเสนอสำหรับทดลองหนึ่งครั้งใช้ได้แค่ 1 ครั้งต่อรายการ",
    eventRule2:
      "*ราคาอีเว้นท์ และทดลองหนึ่งครั้งสามารถใช้ได้เมื่อ เพิ่มเพื่อนในแอพ LINE(LINE ID: @priaclinic.th) (4/1~4/30)",
    intro1: "คลินิก PRIA ที่เป็น",
    intro2: "ส่วนตัวที่สุดสำหรับคุณ",
    intro3: "พรีอาตั้งใจให้เป็นสถานที่ที่",
    intro4: "อบอุ่น และส่วนตัวสำหรับคุณโดยเฉพาะ",
    intro5: "ความงามเริ่มต้นจาก",
    intro6: "ความซื่อสัตย์ที่สามารถเชื่อถือได้",
    intro7: "เราจะให้ความสำคัญกับการได้พบคุณ",
    intro8: "และมอบความงามที่จริงใจให้กับคุณ",
    intro9: "พรีอามีราคาที่โปร่งใส และยึดมั่นในสินค้าของแท้",
    intro10: "และใช้ปริมาณที่ถูกต้องอย่างเคร่งครัด",
    intro11: "เราจะให้ความสำคัญกับลูกค้าทุกท่าน",
    intro12: "เรามอบบริการโดยทีมแพทย์ที่มีความเชี่ยวชาญ",
    intro13: "ให้การรักษาแบบ 1:1 เพื่อความเหมาะสมของแต่ละบุคคล ",
    intro14: "เราจะใช้เครื่องมือวินิจฉัยเป็นตัวกลาง",
    intro15: "เพื่อให้การดูแลที่มีพื้นฐานทางวิทยาศาสตร์",
    intro16: "การให้คำปรึกษาของเราจะไม่เป็นการชักชวนที่ไม่จำเป็น",
    intro17: "แต่จะให้ข้อมูลที่ถูกต้องเกี่ยวกับการรักษาแทน",
    doctors: "ทีมแพทย์ PRIA",
    intro_clinic: "แนะนำโรงพยาบาล",
    intro_personnel: "แนะนำทีมแพทย์",
    //หน้าตะกร้าสินค้า
    reservationComplete: "จองเรียบร้อย!",
    cartIsEmpty: "ตะกร้าสินค้าของคุณว่างเปล่า",
    chooseDate: "กรุณาเลือกวันที่ และเวลา",
    chooseProcedure: "เลือกการรักษาด้วยตนเอง",
    afterCouncel: "ตัดสินใจหลังจากการปรึกษา",
    pleaseChooseProcedure1: "กรุณาเพิ่ม",
    pleaseChooseProcedure2: "การรักษาหรือกิจกรรมที่คุณสนใจ.",
    addProcedure: "เพิ่มการรักษา/กิจกรรม",
    additionalRequirement: "คำขอเพิ่มเติม",
    personalizedConsultation: "การปรึกษาแบบ 1:1",
    personalizedConsultComment1: "หลังจากการปรึกษาแบบ 1:1",
    personalizedConsultComment2: "คุณสามารถตัดสินใจเกี่ยวกับการรักษาได้",
    pleaseWriteRequirement:
      "กรุณากรอกส่วนที่คุณต้องการคำปรึกษาหรือคำถามที่สงสัย",
    pickDate: "เลือกวันที่",
    authen: "ยืนยันตัวตน",
    yourName: "ชื่อ",
    yourPhoneNumberOnly: "หมายเลขโทรศัพท์ (เฉพาะตัวเลข)",
    verifiCode: "หมายเลขยืนยัน",
    required: "จำเป็น",
    iAgree:
      "ฉันยินยอมให้ข้อมูลส่วนบุคคล (ข้อมูลการรักษา) ถูกเปิดเผยแก่บุคคลที่สาม",
    inCaseofFellow:
      "หากมีผู้ที่จะมาพร้อมกับคุณ กรุณากรอกชื่อ และเบอร์ติดต่อของผู้ที่จะมาด้วยในข้อร้องขอเพิ่มเติม :)",
    seeMore: "ดู",
    paymentMethod: "การชำระเงินจะจ่ายเมื่อมาถึงคลินิก",
    VAT: "*ราคาทั้งหมดไม่รวมภาษีมูลค่าเพิ่ม(+10%)",
    //หน้ารายละเอียด
    recommendation: "กลุ่มเป้าหมายสำหรับการรักษา",
    features: "ลักษณะของการรักษา",
    precaution: "ข้อควรระวังหลังการรักษา",
    directReservation: "จองทันที",
    //ไซด์เมนู
    quickBooking: "จองด่วน",
    reservationCheck: "ยืนยันการจอง",
    cart: "ตะกร้าสินค้า",
    priaIntro: "แนะนำ PRIA",
    Insta: "IG",
    SNS: "LINE",
    //ยืนยันการจอง
    reservationDate: "วันที่และเวลาการจอง",
    year_: "ปี",
    month_: "เดือน",
    day_: "วัน",
    time_: "ชั่วโมง",
    minute_: "นาที",
    yourHistory: "ประวัติการจองของคุณ",
    yourRequest: "ข้อความที่คุณร้องขอ",
    yourInquiry: "ติดต่อเราทาง LINE",
    yourCancelRequest: "ยกเลิกการจอง",
    reservationGuide: "",
    pleaseInsertNameAndPhone: "กรุณากรอกชื่อและหมายเลขโทรศัพท์ของคุณ",
    //หน้าค้นหา
    whatrulookingfor: "คุณกำลังมองหาการรักษาประเภทใด?",
    searchwords: "คำค้นหายอดนิยม",
    recentSearch: "คำค้นหาล่าสุด",
    totalPrice: "ค่าใช้จ่ายรวมที่คาดการณ์",
    //ป็อปอัพวิธีการปรึกษา
    consultMethod: "",
    byVisit: "เดินทางไปปรึกษาที่คลินิก",
    byCall: "ปรึกษาทางโทรศัพท์",
    bySNS: "สอบถามทาง LINE",
    closer: "ปิด",
    closePopup1week: "ไม่ต้องแสดงอีกเป็นเวลา 1 สัปดาห์",
  },
  zh: {
    potenzaTitle: "黄金微针",
    potenzaSubtitle: "PRIA的无瑕Potenza",
    ondaTitle: "ONDA微波紧肤",
    ondaSubtitle: "无痛速效！",
    onedayporezeroTitle: "One-Day 毛孔修复疗程",
    onedayporezeroSubtitle: "备受瞩目的热门疗程！",
    juvelookTitle: "Juvelook 水光针",
    juvelookSubtitle: "毛孔、细纹、自然饱满",
    nasolabialTitle: "法令纹填充",
    nasolabialSubtitle: "不同级别的“精细”填充",
    ultheraTitle: "美版超声刀",
    ultheraSubtitle: "1:1定制Ulthera！",
    howwasyourday: "今天的治疗感觉如何？",
    gotosurvey: "前往评价 ❯",
    surveyPolicy:
      "*每月底，我们将从参与问卷调查的用户中抽取5位幸运用户，赠送星巴克优惠券。",
    wrongBirthday: "请输入正确的出生日期。",
    dateofbirth: "出生日期（例如：900101）",
    noReservationHistory: "没有预约记录。",
    reservationCheckComment:
      "您的预约已成功确认。为了更好地为您提供后续服务，请通过消息将您的姓名发送至 PRIA Clinic 官方LINE账号（ID: @priaclinic.tw）。",
    bookingCancelComment: "您的取消预约请求已完成！处理可能需要一些时间。",
    monthlyEventRule: "*本月活动每个项目限使用1次，适用于整个本月。",
    reservationCancelConfirm: "您确定要取消预约吗？",
    yesCancelConfirm: "是",
    noCancelConfirm: "否",
    referToHomepage: "请参考官网价格。",
    afterCounsel: "咨询后确定",
    newYearPrice: "本月特价",
    secondEventName: "瑞珠蓓露克优惠活动",
    oneSessionTrial: "单次体验价",
    openPrice: "开业特价",
    originalPrice: "标准价格",
    booking: "立即预约",
    reservation: "治疗预约",
    reservationTitle: "治疗价格",
    event: "促销活动",
    consult: "在线咨询",
    addToCart: "加入预约清单",
    newComer: "不知道该做哪种微整项目？",
    searchGuide: "搜索您感兴趣的微整项目",
    searchPlaceholder: "请输入关键词",
    priaSignature: "PRIA的特色治疗",
    priaEvent: "PRIA 优惠活动",
    wayToPria: "来院路线",
    address1: "首尔市瑞草区江南大道349号",
    address2: "友南大厦13层 PRIA医院",
    subway: "地铁",
    subway1: "（2号线）江南站7号出口步行3分钟",
    subway2: "（新盆唐线）江南站5号出口步行1分钟",
    bus: "公交",
    bus1: "站点：友成公寓十字路口、水协瑞草分行",
    bus2: "新盆唐线江南站、新盆唐线江南站4号出口",
    taxi: "出租车",
    taxi1: "医院位于1楼水协银行楼上。",
    driving: "自驾",
    driving1: "从建筑后侧进入可使用代客泊车服务。",
    driving2: "停车费用按治疗时间免费提供，",
    driving3: "代客泊车服务另收3000韩元。",
    workingHour: "营业时间",
    workingHour1: "工作日",
    workingHour2: "周六",
    workingHour3: "节假日正常营业（春节及中秋当天除外）",
    workingHour4: "周日休诊",
    policy: "用户条款",
    personalPolicy: "个人信息处理方针",
    keywordAlert: "请输入关键词",
    openSpecialPrice: "开业特别优惠",
    openEventRule1: "*开业特别优惠仅适用于12/23至1/4。",
    openEventRule2: "*活动优惠仅限添加Kakao好友用户，每个项目可享受1次优惠。",
    newyearEventRule1:
      "*新年活动优惠仅适用于1月1日至1月31日期间，每个项目限使用1次。",
    eventRule1: "*单次体验价每个项目限1次适用。",
    eventRule2:
      "添加WeChat或Line好友即可享受活动优惠价和首次体验价的专属福利！(4/1~4/30)",
    intro1: "专为您打造的，",
    intro2: "顶级私密医院，PRIA",
    intro3: "PRIA诞生于对您的专属呵护,",
    intro4: "只为打造属于您的温馨空间",
    intro5: "从值得信赖的诚信中，",
    intro6: "开启属于您的美丽蜕变。",
    intro7: "我们珍视与您的每次相遇，",
    intro8: "致力于带给您真实而动人的美丽。",
    intro9: "PRIA坚持透明的价格政策，",
    intro10: " 严格保障正品与标准用量。",
    intro11: "我们专注于您的每一个需求。",
    intro12: "通过经验丰富的医疗团队，",
    intro13: "根据每位客户的特性量身定制1对1服务。",
    intro14: "借助先进的诊断设备，",
    intro15: " 为您提供更加科学、专业的护理服务。",
    intro16: "PRIA拒绝不必要的推销，",
    intro17: "专注于提供精准的治疗方案与专业的咨询服务。",
    doctors: "PRIA 医生团队",
    intro_clinic: "医院介绍",
    intro_personnel: "医生介绍",
    //购物车画面,
    reservationComplete: "预约成功！",
    cartIsEmpty: "购物车为空。",
    chooseDate: "请选择日期和时间。",
    chooseProcedure: "直接选择治疗项目",
    afterCouncel: "咨询后决定",
    pleaseChooseProcedure1: "请添加感兴趣的治疗或活动。",
    pleaseChooseProcedure2: "将项目加入预约清单。",
    addProcedure: "添加治疗/活动项目",
    additionalRequirement: "附加需求",
    personalizedConsultation: "1对1定制咨询",
    personalizedConsultComment1: "通过1对1咨询后，",
    personalizedConsultComment2: "可决定治疗项目。",
    pleaseWriteRequirement: "请自由填写希望咨询的部位或想了解的内容。",
    pickDate: "选择日期",
    authen: "身份认证",
    yourName: "姓名",
    yourPhoneNumberOnly: "电话号码（仅数字）",
    verifiCode: "验证码",
    required: "必填",
    iAgree: "本人同意将个人（诊疗）信息提供给第三方使用。",
    inCaseofFellow:
      "如果有同行者，请务必在附加需求中填写同行者的姓名和联系方式:)",
    seeMore: "查看更多",
    paymentMethod: "本院所有付款均需来院后支付。",
    VAT: "*所有价格均不含增值税。(+10%)",
    //详细画面,
    recommendation: "推荐人群",
    features: "治疗特点",
    precaution: "治疗后注意事项",
    directReservation: "直接预约",
    //侧边栏,
    quickBooking: "快速预约",
    reservationCheck: "查看预约",
    cart: "购物车",
    priaIntro: "关于PRIA",
    Insta: "Instagram",
    SNS: "LINE",
    //预约确认,
    reservationDate: "预约日期",
    year_: "年",
    month_: "月",
    day_: "日",
    time_: "时",
    minute_: "分",
    yourHistory: "的预约记录",
    yourRequest: "咨询内容",
    yourInquiry: "在线咨询",
    yourCancelRequest: "取消请求",
    reservationGuide: "",
    pleaseInsertNameAndPhone: "请输入您的姓名和电话号码。",
    //搜索页面,
    whatrulookingfor: "在寻找哪种治疗项目？",
    searchwords: "热门搜索关键词",
    recentSearch: "最近搜索记录",
    totalPrice: "总预计金额",
    //咨询方式弹窗,
    consultMethod: "",
    byVisit: "现场咨询",
    byCall: "电话咨询",
    bySNS: "LINE在线咨询",
    closer: "关闭窗口",
    closePopup1week: "一周内不再显示",
  },
};

export default locales;
