import React, { useState, useEffect } from "react";
import { Box, Grid2 } from "@mui/material";
import { useSwipeable } from "react-swipeable";
import { useNavigate } from "react-router-dom";
import { ref, getDownloadURL, listAll } from "firebase/storage";
import { getDoc, doc } from "firebase/firestore";
import { storage, firestore } from "../service/firebaseConfig"; // Adjust the import path as needed
import { useLanguage } from "../LanguageContext";
import ko_0 from "./images/ko_0.jpg";
import en_0 from "./images/en_0.jpg";
import jp_0 from "./images/jp_0.jpg";
import th_0 from "./images/th_0.jpg";
import zh_0 from "./images/zh_0.jpg";

type ImageUrl = {
  name: string;
  url: string;
};

const EventBanner = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imageUrls, setImageUrls] = useState<ImageUrl[]>([]);
  const [imageLength, setImageLength] = useState(10);
  const [links, setLinks] = useState<string[]>([]);
  const navigate = useNavigate();
  const { language } = useLanguage(); // 현재 언어 상태 가져오기

  // 자동 슬라이드
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % imageLength);
    }, 5000); // 5초마다 변경
    return () => clearInterval(interval); // 컴포넌트 언마운트 시 정리
  }, [imageLength]);

  // Swipe 이벤트 핸들러
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handlePrev(),
  });

  // 다음 이미지로 이동
  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % imageLength);
  };

  // 이전 이미지로 이동
  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + imageLength) % imageLength);
  };
  const nationalities: Record<string, string> = {
    ko: ko_0,
    jp: jp_0,
    en: en_0,
    th: th_0,
    zh: zh_0,
  };
  async function fetchImages(): Promise<ImageUrl[]> {
    const folderRef = ref(storage, `EventBanners/${language}`); // "EventProcedure" 폴더 참조
    const imageUrls: ImageUrl[] = [];

    try {
      // 폴더의 모든 항목 가져오기
      const result = await listAll(folderRef);
      setImageLength(result.items.length + 1);
      const docRef = doc(firestore, "search", "EventLinks");
      const docSnap = await getDoc(docRef);
      // 각 항목의 URL과 이름 가져오기
      for (const itemRef of result.items) {
        const url = await getDownloadURL(itemRef); // 이미지 URL 가져오기
        imageUrls.push({
          name: itemRef.name, // 파일 이름
          url: url, // 다운로드 URL
        });
      }
      if (docSnap.exists()) {
        setLinks(docSnap.data().links as string[]);
      }
      return imageUrls;
    } catch (error) {
      console.error("Error fetching images:", error);
      return [];
    }
  }
  useEffect(() => {
    fetchImages().then((imageUrls) => setImageUrls(imageUrls));
  }, []);

  return (
    <Box
      sx={{
        width: window.innerWidth > 750 ? "80%" : "90%",
        overflow: "hidden",
        position: "relative",
      }}
      {...swipeHandlers} // Swipeable 이벤트 연결
    >
      <Grid2
        container
        sx={{
          display: "flex",
          transform: `translateX(-${currentIndex * 100}%)`,
          transition: "transform 0.5s ease-in-out",
          flexWrap: "nowrap",
        }}
      >
        <img
          src={nationalities[language]}
          style={{
            minWidth: "100%",
            width: "100%",
            height: "auto",
            cursor: "pointer",
          }}
          onClick={() => navigate(`/${language}/event?category=-9`)}
        />
        {imageUrls.map((item, index) => (
          <img
            src={item.url}
            key={index}
            style={{
              minWidth: "100%",
              width: "100%",
              height: "auto",
              cursor: "pointer",
            }}
            onClick={() => {
              if (links[index]) {
                navigate(`/${language}/event?category=${links[index]}`);
              }
            }}
          />
        ))}
      </Grid2>
    </Box>
  );
};

export default EventBanner;
