import React, { useState, useEffect, ChangeEvent } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { collection, getDoc, getDocs, doc } from "firebase/firestore";
import { firestore } from "../service/firebaseConfig.js";
import { Box, Typography, TextField, IconButton, Grid2 } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SearchIcon from "@mui/icons-material/Search";
import BackArrow from "./images/BackArrow.png";
import { useLanguage } from "../LanguageContext";
import locales from "./text_assets/locale.js";

interface SearchMaterials {
  category: string;
  title: string;
  description: string;
  price: number;
}

interface WholeSearchProps {
  searchText: string;
  setSearchText: (arg0: string) => void;
  searchOpen: boolean;
  setSearchOpen: (arg0: boolean) => void;
  lastParams: string;
}

const WholeSearch: React.FC<WholeSearchProps> = ({
  searchText,
  setSearchText,
  searchOpen,
  setSearchOpen,
  lastParams,
}) => {
  const [searchQuery, setSearchQuery] = useState<string>(
    searchText ? searchText : ""
  );
  // 검색결과
  const [showResults, setShowResults] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<SearchMaterials[]>([]);

  //최근 검색어
  const [recentSearches, setRecentSearches] = useState<string[]>(
    JSON.parse(localStorage.getItem("recentSearches") || "[]")
  );

  //인기 검색어
  const [topKeywords, setTopKeywords] = useState<string[]>([
    "온다",
    "울쎄라",
    "포어제로 테라피",
  ]);

  //언어
  const { language } = useLanguage(); // 현재 언어 상태 가져오기
  type localeKey = keyof typeof locales;
  const langkey: localeKey = language as localeKey;
  const { whatrulookingfor, searchwords, searchPlaceholder, recentSearch } =
    locales[langkey];

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  // URL 파라미터로 검색어 받아오기
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const topKeywordsFetch = async () => {
    const keywordsNationality =
      language === "ko" ? "keywords" : `keywords_${language}`;
    try {
      const docRef = doc(firestore, "search", keywordsNationality);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setTopKeywords(docSnap.data().keywords);
      }
    } catch (error) {
      console.error("Error fetching searching keywords:", error);
    }
  };

  const fetchAndSearch = async (searchWord: string) => {
    if (!searchWord.trim()) {
      alert("검색어를 입력해주세요.");
      return;
    }
    setSearchParams({ keyword: searchWord });
    const updatedSearches = [
      searchWord,
      ...recentSearches.filter((term) => term !== searchWord),
    ].slice(0, 5);
    localStorage.setItem("recentSearches", JSON.stringify(updatedSearches));
    const categoryNationality =
      language === "ko" ? "category" : `category_${language}`;
    const eventNationality = language === "ko" ? "event" : `event_${language}`;
    setRecentSearches(updatedSearches);
    try {
      const querySnapshot = await getDocs(
        collection(firestore, categoryNationality)
      );
      const querySnapshot2 = await getDocs(
        collection(firestore, eventNationality)
      );
      const results: SearchMaterials[] = [];
      const cleanedSearchWord =
        language !== "jp" ? searchWord.replace(/\s+/g, "") : searchWord; // 검색어의 공백 제거

      querySnapshot.forEach((doc) => {
        doc.data().contents.forEach((content: any) => {
          const cleanedTitle =
            language === "ko"
              ? content.title.replace(/\s+/g, "")
              : content.title; // 타이틀의 공백 제거
          const cleanedDescription =
            language === "ko"
              ? content.description.replace(/\s+/g, "")
              : content.description; // 설명의 공백 제거
          // console.log("cleanedDescription", cleanedDescription);
          if (
            cleanedTitle?.includes(cleanedSearchWord) ||
            cleanedDescription?.includes(cleanedSearchWord)
          ) {
            results.push({
              category: doc.data().category,
              title: content.title,
              description: content.description,
              price: content.price,
            });
          }
        });
      });
      querySnapshot2.forEach((doc) => {
        const cleanedTitle = doc.data().title?.replace(/\s+/g, ""); // 타이틀의 공백 제거
        const cleanedDescription = doc.data().description?.replace(/\s+/g, ""); // 설명의 공백 제거
        if (
          cleanedTitle.includes(cleanedSearchWord) ||
          cleanedDescription.includes(cleanedSearchWord)
        ) {
          results.push({
            category: doc.data().category?.toString(),
            title: doc.data().title,
            description: doc.data().description,
            price: doc.data().price,
          });
        }
      });
      setSearchResults(results);
      setShowResults(true);
    } catch (error) {
      console.error("Error fetching items data:", error);
    }
  };

  useEffect(() => {
    const storedSearches = localStorage.getItem("recentSearches");
    if (storedSearches) {
      setRecentSearches(JSON.parse(storedSearches));
    }
    if (searchParams.has("keyword")) {
      fetchAndSearch(searchText);
    }
    window.scrollTo(0, 0);
    topKeywordsFetch();
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "750px",
        backgroundColor: "white",
        zIndex: 100,
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Box
        sx={{
          width: "20%",
          display: "flex",
          justifyContent: "center",
          marginTop: "19px",
        }}
      >
        <img
          src={BackArrow}
          alt="back"
          style={{
            width: "20px",
            height: "20px",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            setSearchOpen(false);
            navigate(lastParams);
          }}
        />
      </Box>
      <Box
        sx={{
          width: "80%",
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
          marginTop: 2,
          marginRight: "10%",
        }}
      >
        <Typography
          color="black"
          fontWeight="600"
          fontFamily="Pretendard"
          sx={{ textAlign: "left", fontSize: "1.125rem" }}
        >
          {whatrulookingfor}
        </Typography>
        <Box
          sx={{
            display: "flex",
            position: "relative",
            alignItems: "center",
            gap: 1,
          }}
        >
          <TextField
            variant="standard"
            placeholder={searchPlaceholder}
            sx={{
              width: "100%",
              "& .MuiInputBase-input::placeholder": {
                color: "#cdc5c1", // placeholder 색상 설정
                fontWeight: "bold",
                fontSize: "1.125rem",
              },
              "& .MuiInputBase-input": {
                color: "grey", // 실제 입력한 텍스트의 색상 설정
              },
              "& .MuiInput-underline": {
                borderBottom: "2px solid black", // 밑줄 두껍게
              },
              input: {
                fontFamily: "Pretendard",
                fontSize: "1.125rem",
                fontWeight: "500",
              }, // This targets the input styles directly
            }}
            fullWidth
            value={searchQuery} // Bind the input value to the state
            onChange={handleSearchChange} // Handle input changes
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                fetchAndSearch(searchQuery);
              }
            }}
          />
          <IconButton
            sx={{ position: "absolute", right: 0, paddingRight: 0 }}
            onClick={() => {
              fetchAndSearch(searchQuery);
            }}
          >
            <SearchIcon
              sx={{
                fontSize: 30, // 크기 설정 (기본적으로 fontSize로 크기 조절)
                color: "black", // 색상 설정
              }}
            />
          </IconButton>
        </Box>
        {!showResults && recentSearches.length > 0 && (
          <div>
            <Typography sx={{ fontFamily: "Pretendard", fontSize: "1rem" }}>
              {recentSearch}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                mt: 1,
              }}
            >
              {recentSearches.map((term, index) => (
                <Typography
                  key={index}
                  sx={{
                    border: "1px solid grey",
                    backgroundColor: "white",
                    padding: "3px 7px 3px 7px",
                    borderRadius: 1,
                    color: "grey",
                    fontFamily: "Pretendard",
                    marginRight: 1,
                    marginBottom: 1,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    // setSearchText(term);
                    setSearchQuery(term);
                    fetchAndSearch(term);
                  }}
                >
                  {term}
                </Typography>
              ))}
            </Box>
          </div>
        )}
        {!showResults && (
          <Box>
            <Typography sx={{ fontFamily: "Pretendard", fontSize: "1rem" }}>
              {searchwords}
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", mt: 1 }}>
              {topKeywords.map((keyword, index) => (
                <Typography
                  key={index}
                  onClick={() => {
                    setSearchQuery(keyword);
                    setSearchText(keyword);
                    fetchAndSearch(keyword);
                  }}
                  sx={{
                    backgroundColor: "grey",
                    padding: "3px 7px 3px 7px",
                    borderRadius: 1,
                    color: "white",
                    fontFamily: "Pretendard",
                    marginRight: 1,
                    cursor: "pointer",
                    fontSize: "0.9rem",
                  }}
                >
                  {keyword}
                </Typography>
              ))}
              {/* <Typography
                onClick={() => {
                  setSearchQuery("울쎄라");
                  setSearchText("울쎄라");
                  fetchAndSearch("울쎄라");
                }}
                sx={{
                  backgroundColor: "grey",
                  padding: "3px 7px 3px 7px",
                  borderRadius: 1,
                  color: "white",
                  fontFamily: "Pretendard",
                  marginRight: 1,
                  cursor: "pointer",
                  fontSize: "0.9rem",
                }}
              >
                울쎄라
              </Typography>
              <Typography
                onClick={() => {
                  setSearchQuery("필러");
                  setSearchText("필러");
                  fetchAndSearch("필러");
                }}
                sx={{
                  backgroundColor: "grey",
                  padding: "3px 7px 3px 7px",
                  borderRadius: 1,
                  color: "white",
                  fontFamily: "Pretendard",
                  marginRight: 1,
                  cursor: "pointer",
                  fontSize: "0.9rem",
                }}
              >
                필러
              </Typography> */}
            </Box>
          </Box>
        )}
        {searchResults.length > 0
          ? searchResults.map((result, index) => (
              <Link
                to={`/${language}/detail/${result.category}`}
                onClick={() => setSearchOpen(false)}
                style={{ textDecoration: "none" }}
                key={index}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    bgcolor: "#f6f6f2",
                    borderColor: "transparent",
                  }}
                  key={index}
                >
                  <Grid2
                    container
                    key={10 + index}
                    sx={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      px: 2,
                      py: 2,
                    }}
                  >
                    <Grid2
                      size={{ xs: 12 }}
                      key={index}
                      sx={{
                        width: "90%",
                        flexDirection: "column",
                        display: "flex",
                        alignItems: "flex-start",
                      }}
                    >
                      {/* <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}> */}
                      <Typography
                        sx={{
                          fontFamily: "Pretendard",
                          fontSize: "1rem",
                          fontWeight: "500",
                          color: "#202124",
                        }}
                        key={index}
                      >
                        {result.title}
                      </Typography>
                      <Typography
                        fontSize="0.85rem"
                        color="textSecondary"
                        fontFamily="Pretendard"
                        key={10 + index}
                      >
                        {result.description ? (
                          result.description.split("@").map((line, index) => (
                            <span key={index}>
                              {line}
                              <br />
                            </span>
                          ))
                        ) : (
                          <br />
                        )}
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Pretendard",
                          fontSize: "1rem",
                          fontWeight: "500",
                          color: "#202124",
                        }}
                      >
                        {result.price > 0
                          ? language === "ko"
                            ? `${result.price.toLocaleString("en-US")}원`
                            : `₩${result.price.toLocaleString("en-US")}`
                          : "상담 후 결정"}
                      </Typography>
                    </Grid2>
                    <Box key={10 + index}>
                      <ArrowForwardIosIcon
                        sx={{ width: 18, height: 18 }}
                        key={index}
                      />
                    </Box>
                  </Grid2>
                </Box>
              </Link>
            ))
          : showResults && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                일치하는 상품 검색 결과가 없습니다.
              </Box>
            )}
      </Box>
    </Box>
  );
};

export default WholeSearch;
